<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col>
                <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            예약확인
                        </h1>
                    </v-col>
                </v-row>
                <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            예약확인
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container class="my-8">
                    <v-row>
                        <!--<v-col cols="12" class="my-8">-->
                        <v-col cols="12" class="reservation-select-title mt-6">
                            예약현황
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="hidden-sm-and-down">
                            <div class="overflow-x-auto">
                                <table class="boardTable table-font-size-1rem " style="min-width: 380px !important; width: 100%">
                                    <colgroup>
                                        <col style="width:15%"/>

                                        <col style="width:20%"/>
                                        <col style="width:15%"/>
                                        <col style="width:15%"/>
                                        <col style="width:20%"/>
                                        <col style="width:15%"/>

                                    </colgroup>
                                    <tr>
                                        <th>객실명</th>
                                        <th>예약일</th>
                                        <th>기간</th>
                                        <th>이용요금</th>
                                        <th>신청일</th>
                                        <th>예약상태</th>
                                    </tr>
                                    <tr
                                            v-for="(item,i) in resvConfirmList"
                                            :key="i"
                                            @click="rowClick(item)"
                                            style="cursor: pointer;"
                                    >

                                        <td>{{item.resvRoomNm}}</td>
                                        <td>{{item.resvDate}}</td>
                                        <td>{{item.resvDateRange}}</td>
                                        <td>{{item.resvPrice | comma}}원</td>
                                        <td>{{item.lstModDate}}</td>
                                        <td>
                                            <template v-if="item.resvGubun === '1'">
                                                <span style="color:blue;">{{item.resvGubunDesc}}</span>
                                            </template>
                                            <template v-else-if="item.resvGubun === '2'">
                                                <span style="color:black;">{{item.resvGubunDesc}}</span>
                                            </template>
                                            <template v-else>
                                                <span style="color:red;">{{item.resvGubunDesc}}</span>
                                            </template>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </v-col>

                        <v-col cols="12" class="hidden-md-and-up">
                            <div class="overflow-x-auto">
                                <table class="adminListTable " style="min-width: 330px !important; width: 100%">
                                    <tr
                                            v-for="(item,i) in resvConfirmList"
                                            :key="i"
                                            @click="rowClick(item)"
                                            style="cursor: pointer;"
                                    >
                                        <td style="padding:10px 15px; font-size: 1rem;">
                                            <span style="float: left">{{item.resvRoomNm}}</span>
                                            <span style="float: left" class="mx-2">|</span>
                                            <span style="float:left;">( {{item.resvDateRange}} )</span>
                                            <span style="float: right;">{{item.resvDate}}</span>
                                            <br>

                                            <span style="float: left; margin-top: 5px;">{{item.resvPrice | comma}}원</span>
                                            <span style="float: right; margin-top: 5px;">
                                            <template v-if="item.resvGubun === '1'">
                                                <span style="color:blue;">{{item.resvGubunDesc}}</span>
                                            </template>
                                            <template v-else-if="item.resvGubun === '2'">
                                                <span style="color:black;">{{item.resvGubunDesc}}</span>
                                            </template>
                                            <template v-else>
                                                <span style="color:red;">{{item.resvGubunDesc}}</span>
                                            </template>
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    export default {
        name: 'ReservationSelect',
        components: {},
        data: () => ({
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            headers: [

                {text: '예약번호', value: 'resvId'},
                {text: '예약일', value: 'resvDate'},
                {text: '결제금액', value: 'resvPrice'},
                {text: '상태', value: 'resvGubunDesc'},
            ],
            resvConfirmList: [],
            resvConfirmInfo: {
                resvName: "",
                resvPw: "",
                resvPhone: "",
                resvStartDate: "",

            },
            resvUsrInfo: {},

        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            this.resvUsrInfo = JSON.parse(JSON.stringify(this.$store.state.resvConfirm.resvUsrInfo));

            // 현재 날자 구하기
            let today = new Date();
            let year = today.getFullYear();
            let month = ('0' + (today.getMonth() + 1)).slice(-2);
            let day = ('0' + today.getDate()).slice(-2);
            let dateString = year + '-' + month + '-' + day;


            this.resvUsrInfo.resvStartDate = dateString;

            let isCheck = true;

            /*if (this.resvUsrInfo.resvName.length === 0) {
                isCheck = false;
            }*/

            if (this.resvUsrInfo.isPw) {
                if (this.resvUsrInfo.resvPw.length === 0) {
                    isCheck = false;
                }
            }

            if (this.resvUsrInfo.resvPhone.length === 0) {
                isCheck = false;
            }

            if (isCheck) {
                this.fetch();
            } else {
                this.$router.push({path: "/reservation/resvConfirm"});
            }

        },
        methods: {
            fetch() {
                return this.$store.dispatch("resvConfirm/getList", this.resvUsrInfo)
                    .then((resp) => {
                        setTimeout(() => {
                            this.resvConfirmList = JSON.parse(JSON.stringify(this.$store.state.resvConfirm.resvConfirmList));

                            if (resp.total === "0") {
                                alert("예약정보가 없습니다.");
                                this.$router.push({path: "/reservation/resvConfirm"});
                            }

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            rowClick(row) {

                location.href = '/reservation/resvConfirmInfo' + '?resvId=' + row.resvId + '&resvPhone=' + row.resvPhone;
                //+ '&resvName=' + row.resvName;

            }
        },
        mounted() {

            this.$nextTick(() => {

            })
        },
        computed: {
        },
    }
</script>

<style>

</style>
